import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VForm,
        {
          ref: "add-tracker-tag-form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(VFlex, { attrs: { xs12: "" } }, [_c("view-title")], 1),
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VCard,
                { staticClass: "w100" },
                [
                  _c(VCardTitle, { attrs: { "primary-title": "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Tracker Details")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "v-card-content" },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "", md7: "" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Tracker Name",
                                  rules: _vm.nameRules
                                },
                                model: {
                                  value: _vm.trackerName,
                                  callback: function($$v) {
                                    _vm.trackerName = $$v
                                  },
                                  expression: "trackerName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs12: "", md7: "" } },
                            [
                              _c(VTextarea, {
                                attrs: {
                                  outline: "",
                                  "auto-grow": "",
                                  label: "Tracker Description",
                                  rows: "4",
                                  rules: _vm.descriptionRules,
                                  maxlength: "240",
                                  counter: "240"
                                },
                                model: {
                                  value: _vm.trackerDesc,
                                  callback: function($$v) {
                                    _vm.trackerDesc = $$v
                                  },
                                  expression: "trackerDesc"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("tracking-schedule", {
                attrs: {
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                  isIndefinite: _vm.isIndefinite,
                  rules: _vm.descriptionRules
                },
                on: {
                  "update:startDate": function($event) {
                    _vm.startDate = $event
                  },
                  "update:start-date": function($event) {
                    _vm.startDate = $event
                  },
                  "update:endDate": function($event) {
                    _vm.endDate = $event
                  },
                  "update:end-date": function($event) {
                    _vm.endDate = $event
                  },
                  "update:isIndefinite": function($event) {
                    _vm.isIndefinite = $event
                  },
                  "update:is-indefinite": function($event) {
                    _vm.isIndefinite = $event
                  }
                }
              })
            ],
            1
          ),
          _vm.modified
            ? _c("save-footer", {
                attrs: {
                  cancelAction: _vm.reset,
                  saveLabel: "Create",
                  saveAction: _vm.create
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }