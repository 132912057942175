<template>
  <v-card class="w100 my-4">
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Tracking Schedule</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout row>
        <v-flex xs12 md7>
          <div class="H6-Secondary-Left mb-3">
            Select Dates
          </div>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md3>
          <date-picker
            :text-field-props="{
              'hide-details': false,
              label: 'Start Date',
              rules: startDateRules,
            }"
            :picker-props="{ min: today }"
            :value="startDate"
            @update-date="onPropChange('startDate', $event)"
          ></date-picker>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md3>
          <v-checkbox
            hide-details
            class="checkbox"
            :value="isIndefinite"
            @change="onPropChange('isIndefinite', $event)"
            label="Indefinite"
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md3>
          <date-picker
            :text-field-props="{
              'hide-details': false,
              disabled: isIndefinite,
              label: 'End Date',
              rules: endDateRules,
            }"
            :menu-props="{ disabled: isIndefinite }"
            :picker-props="{ min: startDate || today }"
            :value="endDate"
            @update-date="onPropChange('endDate', $event)"
          ></date-picker>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon';

import rules from '@/rules';
import datePicker from '@/components/datePicker';

export default {
  components: {
    datePicker,
  },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    isIndefinite: {
      type: Boolean,
    },
  },
  data: () => ({
    today: DateTime.local().toISODate(),
    startDateRules: [rules.required('Start date cannot be empty.')],
  }),
  computed: {
    endDateRules() {
      const required = (v) => {
        if (this.isIndefinite) {
          return true;
        }

        return !!v || 'End date cannot be empty.';
      };

      return [required, this.endDateAfter];
    },
  },
  methods: {
    endDateAfter(v) {
      if (!this.startDate || this.isIndefinite) {
        return true;
      }

      const start = DateTime.fromISO(this.startDate);
      const end = DateTime.fromISO(v);
      return end >= start || 'End date cannot be before start date.';
    },
    onPropChange(key, value) {
      if (key === 'isIndefinite' && value) {
        this.$emit(`update:endDate`, undefined);
      }

      this.$emit(`update:${key}`, value);
    },
  },
};
</script>

<style scoped>
.checkbox >>> label {
  font-family: Avenir;
  font-size: 22.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #090c9b;
}
</style>
