<template>
  <v-container grid-list-xl>
    <v-form ref="add-tracker-tag-form" v-model="valid">
      <v-flex xs12>
        <view-title />
      </v-flex>
      <v-layout row wrap>
        <v-card class="w100">
          <v-card-title primary-title>
            <div class="H5-Primary-Left">Tracker Details</div>
          </v-card-title>
          <div class="v-card-content">
            <v-layout row wrap>
              <v-flex xs12 md7>
                <v-text-field v-model="trackerName" label="Tracker Name" :rules="nameRules" />
              </v-flex>
              <v-flex xs12 md7>
                <v-textarea
                  outline
                  auto-grow
                  label="Tracker Description"
                  rows="4"
                  v-model="trackerDesc"
                  :rules="descriptionRules"
                  maxlength="240"
                  counter="240"
                />
              </v-flex>
            </v-layout>
          </div>
        </v-card>
        <tracking-schedule
          :startDate.sync="startDate"
          :endDate.sync="endDate"
          :isIndefinite.sync="isIndefinite"
          :rules="descriptionRules"
        />
      </v-layout>
      <save-footer v-if="modified" :cancelAction="reset" saveLabel="Create" :saveAction="create" />
    </v-form>
  </v-container>
</template>

<script>
import rules from '@/rules';
import TrackingSchedule from '@/components/Analytics/TrackerTags/Create/TrackingSchedule';

export default {
  async beforeRouteLeave(to, from, next) {
    if (this.modified) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    TrackingSchedule,
  },
  data: () => ({
    trackerDesc: undefined,
    trackerName: undefined,
    startDate: undefined,
    endDate: undefined,
    isIndefinite: false,
    valid: true,
  }),
  computed: {
    descriptionRules() {
      return [rules.required('Description cannot be empty.')];
    },
    nameRules() {
      return [
        rules.required('Name cannot be empty.'),
        rules.maxCharacters(25, 'Name cannot be more than 25 characters'),
      ];
    },
    modified() {
      // TODO: fix this when editing becomes a thing
      return (
        this.trackerName || this.trackerDesc || this.startDate || this.endDate || this.isIndefinite
      );
    },
  },
  methods: {
    create() {
      this.$refs['add-tracker-tag-form'].validate();
      if (!this.valid) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }

      this.$toast('Tracker Tag created successfully');
      this.$refs['add-tracker-tag-form'].reset();
      this.$router.push({ name: 'smart-analytics-dashboard' });
    },
    reset() {
      this.$refs['add-tracker-tag-form'].reset();
      this.$toast('All your changes have been reset');
    },
  },
  mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: `Create a Tracker Tag`,
      to: { name: 'add-tracker-tag' },
      last: true,
    });
  },
};
</script>
